// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCz5l1FHBkUdtkj-tTWnj1Bf6KxuD9plIU",
  authDomain: "globalnews-58f1d.firebaseapp.com",
  databaseURL: "https://globalnews-58f1d-default-rtdb.firebaseio.com",
  projectId: "globalnews-58f1d",
  storageBucket: "globalnews-58f1d.appspot.com",
  messagingSenderId: "222094954462",
  appId: "1:222094954462:web:b07d2ce78536b53452d74f",
  measurementId: "G-Z7KP78TRRG",
};
export const provider = new GoogleAuthProvider();
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
