import React from "react";
import "./css/404.css"

const NotFound = ( ) => {
  return (
    <div className = "bg">
      <h1> Error 404 - Page not found! </h1>
    </div>
  )
}

export default NotFound
